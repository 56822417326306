<template>
  <div  class="px-15px px-lg-25px">
    <div class="card">
      <div class="card-header">
        <h5 class="mb-0 h6">{{ $t('xiu-gai-shang-pin-xin-xi') }}</h5>
      </div>
      <div class="card-body">
        <form class="" >
          <div class="row">
            <label class="col-md-2 col-form-label">{{ $t('shang-pin-ming-cheng') }}</label>
            <div class="col-md-10">
              <input type="text" class="form-control mb-3" :placeholder="$t('shang-pin-ming-cheng-0')" v-model="form.GoodsName">
            </div>
          </div>
          <div class="row">
            <label class="col-md-2 col-form-label">{{ $t('fen-lei') }}</label>
            <div class="col-md-10">
              <a-select  class="form-control clear mb-3" size="large" v-model="form.CategoryId" show-search :filter-option="filterOption">
                <a-select-option :value="item.ID" v-for="(item, i) in categoryList" :key="i">{{item.Name}}</a-select-option>
              </a-select>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-md-2 col-form-label">{{ $t('shang-pin-zhu-tu') }}</label>
            <div class="col-md-10">
              <div class="input-group" data-toggle="aizuploader" data-type="image">
                <div class="input-group-prepend">
                  <div class="input-group-text bg-soft-secondary font-weight-medium">{{ $t('liu-lan') }}</div>
                </div>
                <div class="form-control file-amount" @click="addFile(1)">{{ $t('xuan-ze-wen-jian') }}</div>
              </div>
              <previewBar :fileList="fileList" @remove="removeFile"></previewBar>
              <input type="file" class="hide-btn" id="fileInput" @change="uploadFile">
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-md-2 col-form-label">{{ $t('shang-pin-lun-bo-tu') }}</label>
            <div class="col-md-10">
              <div class="input-group" data-toggle="aizuploader" data-type="image">
                <div class="input-group-prepend">
                  <div class="input-group-text bg-soft-secondary font-weight-medium">{{ $t('liu-lan') }}</div>
                </div>
                <div class="form-control file-amount" @click="addFile(2)">{{ $t('xuan-ze-wen-jian') }}</div>
              </div>
              <previewBar :fileList="fileList2" @remove="removeFile2"></previewBar>
              <input type="file" class="hide-btn" id="fileInput" @change="uploadFile">
            </div>
          </div>
          <div class="row">
            <label class="col-md-2 col-form-label">{{ $t('shang-pin-jia-ge') }}</label>
            <div class="col-md-10">
              <input type="number" class="form-control mb-3" :placeholder="$t('shang-pin-jia-ge-0')" v-model="form.Price">
            </div>
          </div>
          <div class="row">
            <label class="col-md-2 col-form-label">{{ $t('shang-pin-shu-xing') }} <span class="text-danger text-danger">*</span></label>
            <div class="col-md-10 mb-3">
              <div class="row" v-for="(item, i) in optionList" :key="i">
                <div class="col-md-2">
                  <input type="text" class="form-control form-control-mini mb-1" :placeholder="$t('shu-xing-ming')" v-model="item.key">
                </div>
                <div class="col-md-5">
                  <input type="text" class="form-control form-control-mini mb-1" :placeholder="$t('shu-xing-zhi')" v-model="item.value">
                </div>
                <div class="col-md-2">
                  <a  class="btn btn-soft-info btn-icon btn-circle btn-mini " :title="$t('xin-zeng')" @click="addOption(i)">
                    <i class="las la-plus"></i>
                  </a>
                  <a  class="btn btn-soft-danger btn-icon btn-circle btn-mini confirm-delete" :title="$t('shan-chu')" @click="removeOption(i)">
                    <i class="las la-trash"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <label class="col-md-2 col-form-label">{{ $t('ku-cun-shu-liang') }} <span class="text-danger text-danger">*</span></label>
            <div class="col-md-10">
              <input type="number" class="form-control mb-3" :placeholder="$t('ku-cun-shu-liang-0')" v-model="form.Stock">
            </div>
          </div>
          <div class="row">
            <label class="col-md-2 col-form-label">{{ $t('jing-xuan') }} <span class="text-danger text-danger">*</span></label>
            <div class="col-md-10">
              <label class="aiz-switch aiz-switch-success mb-3">
                <input  type="checkbox" v-model="form.Featured" >
                <span class="slider round"></span>
              </label>
            </div>
          </div>
          <div class="row">
            <label class="col-md-2 col-form-label">{{ $t('shang-jia') }} <span class="text-danger text-danger">*</span></label>
            <div class="col-md-10">
              <label class="aiz-switch aiz-switch-success mb-3">
                <input  type="checkbox" v-model="form.Shelves" >
                <span class="slider round"></span>
              </label>
            </div>
          </div>
          <div class="row">
            <label class="col-md-2 col-form-label">{{ $t('zhi-tong-che') }} <span class="text-danger text-danger">*</span></label>
            <div class="col-md-10">
              <label class="aiz-switch aiz-switch-success mb-3">
                <input  type="checkbox" v-model="form.ThroughTrain" >
                <span class="slider round"></span>
              </label>
            </div>
          </div>
           
          <div class="form-group mb-0 text-right">
            <button type="button" class="btn btn-sm btn-primary" @click="submit">{{ $t('bao-cun') }}</button>
          </div>
        </form>
      </div>
    </div>

   

  </div>
</template>
<script>
import { Popup } from 'vant'
import pageBar from '@/components/page'
import previewBar from '@/components/fileList'
import { editProduct  } from '@/api/seller'
import { fileUpload  } from '@/api/user'
import { getCategoryList } from '@/api/index'
export default {
  components: {
    pageBar,
    previewBar,
    Popup
  },
  data() {
    return {
      fileList: [],
      fileList2: [],
      form: {},
      fileType: '',
      categoryList: [],
      optionList: []
    }
  },
  mounted() {
    let data = JSON.parse(sessionStorage.getItem('productData'))
    this.form = Object.assign({}, data)
    if (this.form.ImgUrl) {
      this.fileList = [{
        url: this.form.ImgUrl
      }]
    }
    if (this.form.ImgUrls) {
      this.fileList2 = JSON.parse(this.form.ImgUrls).map(v => {
        return {
          url: v
        }
      })
    }
    if (this.form.Attribute) {
      let options = JSON.parse(this.form.Attribute)
      this.optionList = []
      for (let key in options) {
        this.optionList.push({
          key: key,
          value: options[key]
        })
      }
    }
    this.initCategory()
  },
  methods: {
    initCategory() {
      let form = new FormData()
      form.append('current', 1)
      form.append('pageSize', 200)
      getCategoryList(form).then(res => {
        this.categoryList = res.data.Items
      })
    },
    addFile(type) {
      this.fileType = type
      document.getElementById('fileInput').click()
    },
    uploadFile() {
      let file = document.getElementById('fileInput').files[0]
      let form = new FormData()
      form.append('file', file)
      fileUpload(form).then(res => {
        if (res.code == 0) {
          if (this.fileType == 1) {
            this.fileList = [{
              url: `/upload/${res.data}`
            }]
          } else {
            this.fileList2.push({
              url: `/upload/${res.data}`
            })
          }
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    removeFile() {
      this.fileList = []
    },
    removeFile2(i) {
      this.fileList2.splice(i, 1)
    },
    submit() {
      let attributes = {}
      this.optionList.forEach(v => {
        attributes[v.key] = v.value
      })
      let form = new FormData()
      form.append('ID', this.form.ID)
      form.append('CategoryId', this.form.CategoryId)
      form.append('GoodsName', this.form.GoodsName)
      form.append('ImgUrl', this.fileList.map(v => {return v.url}).join())
      form.append('ImgUrls', JSON.stringify(this.fileList2.map(v => {return v.url})))
      form.append('Price', this.form.Price)
      form.append('Attribute', JSON.stringify(attributes))
      form.append('Stock', this.form.Stock)
      form.append('Featured', this.form.Featured ? 1 : 2)
      form.append('Shelves', this.form.Shelves ? 1 : 2)
      form.append('ThroughTrain', this.form.ThroughTrain ? 1 : 2)
      editProduct(form).then(res => {
        if (res.code == 0) {
          this.$toast(this.$t('xiu-gai-cheng-gong'))
          this.$router.push({
            name: 'productList'
          })
        } else {
          this.$toast.fail(res.msg)
        }
      })

    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    addOption(i) {
      this.optionList.splice(i + 1, 0, {
        key: '',
        value: ''
      })
    },
    removeOption(i) {
      this.optionList.splice(i, 1)
    }
  }
}
</script>